<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>News record with id: {{ $route.params.ID }} not found. </span>
      <span>
        <span>Check </span>
        <router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All News</router-link
        >
      </span>
    </vs-alert>

    <vx-card>
      <div class="vx-row w-full">
        <div class="vx-col w-full lg:w-2/3">
          <div class="vx-row w-full">
            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NewsHeasline") }}</label>
              <vs-input
                v-model="newsModel.nameAr"
                class="w-full"
                :data-vv-as="$t('NewsHeasline')"
                name="NewsHeasline"
              />
            </div>
            <!-- <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NewsHeaslineEn") }}</label>
              <vs-input
                v-model="newsModel.nameEn"
                class="w-full"
                :data-vv-as="$t('NewsHeaslineEn')"
                name="NewsHeaslineEn"
              />
            </div> -->

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("Details") }}</label>
              <vs-textarea
                id="Text-area"
                v-model="newsModel.descriptionAr"
                class="w-full"
                :data-vv-as="$t('Details')"
                name="Details"
              />
            </div>

            <!-- <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{
                $t("Details")
              }}</label>
              <vs-textarea  id="Text-area"
                v-model="newsModel.descriptionEn"
                class="w-full"
                :data-vv-as="$t('Details')"
                name="Details"

              />
            </div> -->
          </div>
        </div>
        <!--
        <div class="vx-row w-full lg:w-1/3">
          <vs-card title="icon">
            <h4>{{ $t("ImageUrl") }}</h4>
            <template v-if="newsModel.imageUrl">
              <div
                class="img-container w-64 mx-auto flex items-center justify-center"
              >
                <img :src="baseURL + newsModel.imageUrl" />
              </div>
            </template>

            <div class="modify-img flex justify-between mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                class="mr-4"
                type="flat"
                @click="$refs.updateImgInput.click()"
              >
                Update Image
              </vs-button>
              <vs-button
                type="flat"
                color="#999"
                @click="newsModel.imageUrl = null"
              >
                Remove Image
              </vs-button>
            </div>
          </vs-card>
        </div> -->

        <div class="vx-row w-full lg:w-1/3">
          <vs-card title="icon">
            <h4>{{ $t("ImageUrl") }}</h4>
            <template v-if="newsModel.imageUrl">
              <div
                class="img-container w-32 h-32 mx-auto flex items-center justify-center"
              >
                <img
                  :src="baseURL + newsModel.imageUrl"
                  class="w-full h-full object-contain"
                />
              </div>
            </template>

            <div class="modify-img flex justify-between mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                class="mr-4"
                type="flat"
                @click="$refs.updateImgInput.click()"
              >
                Update Image
              </vs-button>
              <vs-button
                type="flat"
                color="#999"
                @click="newsModel.imageUrl = null"
              >
                Remove Image
              </vs-button>
            </div>
          </vs-card>
        </div>
      </div>
      <div class="vx-row">
        <div style="margin: 0 20px 20px">
          <vs-button class="my-6 rounded-sm" color="warning" @click="addNews">
            {{ $t("Save") }}
          </vs-button>
        </div>

        <div>
          <vs-button class="my-6 rounded-sm" color="warning" @click="cancel">
            {{ $t("Cancel") }}
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VxCard from "../../components/vx-card/VxCard.vue";
import { domain } from "@/gloabelConstant.js";
import moduleNews from "@/store/News/moduleNews.js";

export default {
  components: { VxCard },
  data() {
    return {
      baseURL: domain,
      user_not_found: false,

      newsModel: {
        id: null,
        nameAr: "",
        nameEn: "",
        descriptionAr: "",
        descriptionEn: "",
        imageURL: "",
      },
      NewsList: [],
    };
  },
  computed: {},
  methods: {
    addNews() {
      debugger;
      this.newsModel.nameEn = this.newsModel.nameAr;
      this.newsModel.descriptionEn = this.newsModel.descriptionAr;
      if (this.newsModel.id !== null && this.newsModel.id >= 0) {
        this.$store
          .dispatch("NewsList/updateItem", this.newsModel)
          .then(() => {
            debugger;
            window.showUpdateSuccess();
            this.$vs.loading.close();
            this.initValues();
            this.cancle();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showUpdateFailed(err.response.data.message);
            } else {
              window.showUpdateFailed("");
            }
            this.$vs.loading.close();
          });
      } else {
        debugger;
        this.$store
          .dispatch("NewsList/addItem", this.newsModel)
          .then(() => {
            debugger;
            window.showAddSuccess();
            this.initValues();
            this.$vs.loading.close();
            this.cancle();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showAddFailed(err.response.data.message);
            } else {
              window.showAddFailed("");
            }
            this.$vs.loading.close();
          });
      }
    },
    reset_data() {
      this.initValues();
    },

    initValues() {
      this.newsModel = {};
    },
    async updateCurrImg(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("NewsList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
              this.newsModel.imageURL = res.data;
              this.successUpload();
              this.$vs.loading.close();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },

    // removeImage(item) {
    //   var index = this.unitOwnerModel.NewsGalleries.findIndex(
    //     (x) => x.AttachPath == item.AttachPath
    //   );
    //   this.NewsModel.NewsGalleries.splice(index, 1);
    //   if (item.ID > 0) this.$store.dispatch("unitOwnerModel/DeleteAttachmenByID", item.ID);
    // },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Image uploaded Success",
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!moduleNews.isRegistered) {
      this.$store.registerModule("NewsList", moduleNews);
      moduleNews.isRegistered = true;
    }

    debugger;
    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined && ID != 0) {
      debugger;
      this.$store.dispatch("NewsList/GetItemByID", ID).then((res) => {
        debugger;
        this.newsModel = res.data.data;
        console.log(this.newsModel);
      });
    } else if (ID == undefined) {
      ID == 0;
    }
  },
};
</script>

<style></style>
